import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import Medal_1 from "../../src/Assets/Icons/medals 1.png"
import Medal_2 from "../../src/Assets/Icons/medals 2.png"
import Medal_3 from "../../src/Assets/Icons/medals 3.png"
import Rank_1_Star from "../../src/Assets/Icons/rank 1.png"
import Rank_2_Star from "../../src/Assets/Icons/rank 2.png"
import Rank_3_Star from "../../src/Assets/Icons/rank 3.png"
import Rank_alt_Star from "../../src/Assets/Icons/rank alt.png"
import axios from 'axios';

function ProcessScores(props) {
    const savedTheme = useSelector((state) => state.darkTheme);
    const allEmployees = props.records;
    const [fastTotal, setFastTotal] = useState(0)
    const [spartaTotal, setSpartaTotal] = useState(0)
    const [veeTotal, setVeeTotal] = useState(0)


    useEffect(() => {
        const fetchData = () => {
            // Use Promise.all to make all requests in parallel
            Promise.all([
                axios.get("https://scorboardapi.fastranking.tech/total_v_repair"),
                axios.get("https://scorboardapi.fastranking.tech/total_fastranking"),
                axios.get("https://scorboardapi.fastranking.tech/total_sparta")
            ])
                .then(([vRepairRes, fastrankingRes, spartaRes]) => {
                    setVeeTotal(vRepairRes.data.data);
                    setFastTotal(fastrankingRes.data.data); // Assuming this is for fastranking
                    setSpartaTotal(spartaRes.data.data);
                })
                .catch((err) => {
                    console.log(`Error fetching data: ${err}`);
                });
        };

        // Set the interval
        const intervalId = setInterval(fetchData, 4000);

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);


    const [defaulTheme, setDefaultTheme] = useState({ text: 'purple-600' });
    // useEffect(() => {
    //   if(props.theme == "purple"){
    //     setDefaultTheme(
    //       (values) => 
    //       (
    //         {...values, text_1: 'text-purple-600'},
    //         {...values, text_2: 'text-purple-600'},
    //         {...values, text_3: 'text-purple-600'}
    //       ))
    //   }
    // })
    // if(props.theme == "purple"){
    //   return (
    //     <div className={`w-4/5 m-auto rounded-2xl p-8 pt-2 pb-2 text-xl -mt-6`}>
    //       <div className='grid grid-cols-2'>
    //         <div className={`mr-4 border p-4 rounded-2xl ${savedTheme ? 'bg-black border-neutral-800' : 'bg-white'}`}>

    //           <div className={`w-full grid grid-cols-3 -mt-2 mr-2 mb-2 font-semibold`}>
    //             <div className='p-1 text-start max-w-20 ml-5'>Rank</div>
    //             <div className='p-1 text-start min-w-72 -ml-8'>Name</div>
    //             <div className='p-1 text-end mr-5'>Score</div>
    //           </div>
    //           {
    //             allEmployees.map((value, key) => {
    //               if(allEmployees[2].score > 0){
    //                 if (key >= 3 && key < 8){
    //                   return (
    //                     <div key={key} className={`w-full h-12 border grid grid-cols-3 mb-3 rounded-xl shadow-xl text-xl overflow-hidden relative ${savedTheme ? 'bg-neutral-950 shadow-neutral-950 text-white border-neutral-800' : 'shadow-gray-100 border-purple-600'}`}>
    //                       <div className='p-2 pt-2 w-24 text-center bg-purple-600 text-white rounded-md text-2xl rounded-tr-none rounded-br-none -translate-y-0.5'>{key + 1}</div>
    //                       <div className='p-2 text-start -ml-10 min-w-72'>{value.name}</div>
    //                       <div className='p-2 absolute right-0 pt-2 ml-14 w-24 text-center bg-purple-200 text-black rounded-md text-2xl rounded-tl-none rounded-bl-none' style={{transform: 'translateY(-1px) translateX(8px)'}}>{value.score}</div>
    //                     </div>
    //                   )
    //                 }
    //               }
    //               else{
    //                 if (key >= 0 && key < 5) {
    //                   return (
    //                     <div key={key} className={`w-full h-12 border grid grid-cols-3 mb-3 rounded-xl shadow-xl text-xl overflow-hidden relative ${savedTheme ? 'bg-neutral-950 shadow-neutral-950 text-white border-neutral-800' : 'shadow-gray-100 border-purple-600'}`}>
    //                       <div className='p-2 pt-2 w-24 text-center bg-purple-600 text-white rounded-md text-2xl rounded-tr-none rounded-br-none -translate-y-0.5'>{key + 1}</div>
    //                       <div className='p-2 text-start -ml-10 min-w-72'>{value.name}</div>
    //                       <div className='p-2 absolute right-0 pt-2 ml-14 w-24 text-center bg-purple-200 text-black rounded-md text-2xl rounded-tl-none rounded-bl-none' style={{transform: 'translateY(-1px) translateX(8px)'}}>{value.score}</div>
    //                     </div>
    //                   )
    //                 }
    //               }
    //             })
    //           }
    //         </div>


    //         <div className={`ml-4 border p-4 rounded-2xl ${savedTheme ? 'bg-black border-neutral-800' : 'bg-white'}`}>
    //           <div className='w-full grid grid-cols-3 -mt-2 mr-2 mb-2 font-semibold'>
    //             <div className='p-1 text-start max-w-20 ml-5'>Rank</div>
    //             <div className='p-1 text-start min-w-72 -ml-8'>Name</div>
    //             <div className='p-1 text-end mr-5'>Score</div>
    //           </div>
    //           {
    //             allEmployees.map((value, key) => {
    //               if(allEmployees[2].score > 0){
    //                 if(key > 7 && key < 12){
    //                   return (
    //                     <div key={key} className={`w-full h-12 border grid grid-cols-3 mb-3 rounded-xl shadow-xl text-xl overflow-hidden relative ${savedTheme ? 'bg-neutral-950 shadow-neutral-950 text-white border-neutral-800' : 'shadow-gray-100 border-purple-600'}`}>
    //                       <div className='p-2 pt-2 w-24 text-center bg-purple-600 text-white rounded-md text-2xl rounded-tr-none rounded-br-none -translate-y-0.5'>{key + 1}</div>
    //                       <div className='p-2 text-start -ml-10 min-w-72'>{value.name}</div>
    //                       <div className='p-2 absolute right-0 pt-2 ml-14 w-24 text-center bg-purple-200 text-black rounded-md text-2xl rounded-tl-none rounded-bl-none' style={{transform: 'translateY(-1px) translateX(8px)'}}>{value.score}</div>
    //                     </div>
    //                   )
    //                 }
    //               }

    //               else{
    //                 if(key > 4 && key < 10){
    //                   return (
    //                     <div key={key} className={`w-full h-12 border grid grid-cols-3 mb-3 rounded-xl shadow-xl text-xl overflow-hidden relative ${savedTheme ? 'bg-neutral-950 shadow-neutral-950 text-white border-neutral-800' : 'shadow-gray-100 border-purple-600'}`}>
    //                       <div className='p-2 pt-2 w-24 text-center bg-purple-600 text-white rounded-md text-2xl rounded-tr-none rounded-br-none -translate-y-0.5'>{key + 1}</div>
    //                       <div className='p-2 text-start -ml-10 min-w-72'>{value.name}</div>
    //                       <div className='p-2 absolute right-0 pt-2 ml-14 w-24 text-center bg-purple-200 text-black rounded-md text-2xl rounded-tl-none rounded-bl-none' style={{transform: 'translateY(-1px) translateX(8px)'}}>{value.score}</div>
    //                     </div>
    //                   )
    //                 }
    //               }
    //             })
    //           }
    //         </div>
    //       </div>
    //     </div>
    //   )
    // }


    // // Sparta Telecome
    // if(props.theme == "blue"){
    //   return (
    //     <div className={`w-4/5 m-auto rounded-2xl p-8 pt-2 pb-2 text-xl -mt-6`}>
    //       <div className='grid grid-cols-2'>
    //         <div className={`mr-4 border p-4 rounded-2xl ${savedTheme ? 'bg-black border-neutral-800' : 'bg-white'}`}>

    //           <div className={`w-full grid grid-cols-3 -mt-2 mr-2 mb-2 font-semibold`}>
    //             <div className='p-1 text-start max-w-20 ml-5'>Rank</div>
    //             <div className='p-1 text-start min-w-72 -ml-8'>Name</div>
    //             <div className='p-1 text-end mr-5'>Score</div>
    //           </div>
    //           {
    //             allEmployees.map((value, key) => {
    //               if(allEmployees[2].score > 0){
    //                 if (key >= 3 && key < 8){
    //                   return (
    //                     <div key={key} className={`w-full h-12 border grid grid-cols-3 mb-3 rounded-xl shadow-xl text-xl overflow-hidden relative ${savedTheme ? 'bg-neutral-950 shadow-neutral-950 text-white border-neutral-800' : 'shadow-gray-100 border-blue-600'}`}>
    //                       <div className='p-2 w-24 text-center bg-blue-600 text-white rounded-md rounded-tr-none rounded-br-none text-2xl -translate-y-0.5'>{key + 1}</div>
    //                       <div className='p-2 text-start -ml-10 min-w-96'>{value.name}</div>
    //                       <div className='absolute top-0 right-0 p-2 pt-2 ml-14 w-24 text-center bg-blue-200 text-black rounded-md text-2xl  rounded-tl-none rounded-bl-none' style={{transform: 'translateY(-1px) translateX(8px)'}}>{value.score}</div>
    //                     </div>
    //                   )
    //                 }
    //               }
    //               else{
    //                 if (key >= 0 && key < 5) {
    //                   return (
    //                     <div key={key} className={`w-full h-12 border grid grid-cols-3 mb-3 rounded-xl shadow-xl text-xl overflow-hidden relative ${savedTheme ? 'bg-neutral-950 shadow-neutral-950 text-white border-neutral-800' : 'shadow-gray-100 border-blue-600'}`}>
    //                       <div className='p-2 w-24 text-center bg-blue-600 text-white rounded-md rounded-tr-none rounded-br-none text-2xl -translate-y-0.5'>{key + 1}</div>
    //                       <div className='p-2 text-start -ml-10 min-w-96'>{value.name}</div>
    //                       <div className='absolute top-0 right-0 p-2 pt-2 ml-14 w-24 text-center bg-blue-200 text-black rounded-md text-2xl  rounded-tl-none rounded-bl-none' style={{transform: 'translateY(-1px) translateX(8px)'}}>{value.score}</div>
    //                     </div>
    //                   )
    //                 }
    //               }
    //             })
    //           }
    //         </div>


    //         <div className={`ml-4 border p-4 rounded-2xl ${savedTheme ? 'bg-black border-neutral-800' : 'bg-white'}`}>
    //           <div className='w-full grid grid-cols-3 -mt-2 mr-2 mb-2 font-semibold'>
    //             <div className='p-1 text-start max-w-20 ml-5'>Rank</div>
    //             <div className='p-1 text-start min-w-72 -ml-8'>Name</div>
    //             <div className='p-1 text-end mr-5'>Score</div>
    //           </div>
    //           {
    //             allEmployees.map((value, key) => {
    //               if(allEmployees[2].score > 0){
    //                 if(key > 7 && key < 12){
    //                   return (
    //                     <div key={key} className={`w-full h-12 border grid grid-cols-3 mb-3 rounded-xl shadow-xl text-xl overflow-hidden relative ${savedTheme ? 'bg-neutral-950 shadow-neutral-950 text-white border-neutral-800' : 'shadow-gray-100 border-blue-600'}`}>
    //                       <div className='p-2 pt-2 w-24 text-center bg-blue-600 text-white rounded-md text-2xl rounded-tr-none rounded-br-none -translate-y-0.5'>{key + 1}</div>
    //                       <div className='p-2 text-start -ml-10 min-w-72'>{value.name}</div>
    //                       <div className='p-2 absolute right-0 pt-2 ml-14 w-24 text-center bg-blue-200 text-black rounded-md text-2xl rounded-tl-none rounded-bl-none' style={{transform: 'translateY(-1px) translateX(8px)'}}>{value.score}</div>
    //                     </div>
    //                   )
    //                 }
    //               }

    //               else{
    //                 if(key > 4 && key < 10){
    //                   return (
    //                     <div key={key} className={`w-full h-12 border grid grid-cols-3 mb-3 rounded-xl shadow-xl text-xl overflow-hidden relative ${savedTheme ? 'bg-neutral-950 shadow-neutral-950 text-white border-neutral-800' : 'shadow-gray-100 border-blue-600'}`}>
    //                       <div className='p-2 pt-2 w-24 text-center bg-blue-600 text-white rounded-md text-2xl rounded-tr-none rounded-br-none -translate-y-0.5'>{key + 1}</div>
    //                       <div className='p-2 text-start -ml-10 min-w-72'>{value.name}</div>
    //                       <div className='p-2 absolute right-0 pt-2 ml-14 w-24 text-center bg-blue-200 text-black rounded-md text-2xl rounded-tl-none rounded-bl-none' style={{transform: 'translateY(-1px) translateX(8px)'}}>{value.score}</div>
    //                     </div>
    //                   )
    //                 }
    //               }
    //             })
    //           }
    //         </div>
    //       </div>
    //     </div>
    //   )
    // }


    // // Vee Repairs
    // if(props.theme == "sky-blue"){
    //   return (
    //     <div className={`w-4/5 m-auto rounded-2xl p-8 pt-2 pb-2 text-xl -mt-6`}>
    //       <div className='grid grid-cols-2'>
    //         <div className={`mr-4 border p-4 rounded-2xl ${savedTheme ? 'bg-black border-neutral-800' : 'bg-white'}`}>

    //           <div className={`w-full grid grid-cols-3 -mt-2 mr-2 mb-2 font-semibold`}>
    //             <div className='p-1 text-start max-w-20 ml-5'>Rank</div>
    //             <div className='p-1 text-start min-w-72 -ml-8'>Name</div>
    //             <div className='p-1 text-end mr-5'>Score</div>
    //           </div>
    //           {
    //             allEmployees.map((value, key) => {
    //               if(allEmployees[2].score > 0){
    //                 if (key >= 3 && key < 8){
    //                   return (
    //                     <div key={key} className={`w-full h-12 border grid grid-cols-3 mb-3 rounded-xl shadow-xl text-xl overflow-hidden relative ${savedTheme ? 'bg-neutral-950 shadow-neutral-950 text-white border-neutral-800' : 'shadow-gray-100 border-sky-600'}`}>
    //                       <div className='p-2 w-24 text-center bg-sky-600 text-white rounded-md rounded-tr-none rounded-br-none text-2xl -translate-y-0.5'>{key + 1}</div>
    //                       <div className='p-2 text-start -ml-10 min-w-96'>{value.name}</div>
    //                       <div className='absolute top-0 right-0 p-2 pt-2 ml-14 w-24 text-center bg-sky-200 text-black rounded-md text-2xl  rounded-tl-none rounded-bl-none' style={{transform: 'translateY(-1px) translateX(8px)'}}>{value.score}</div>
    //                     </div>
    //                   )
    //                 }
    //               }
    //               else{
    //                 if (key >= 0 && key < 5) {
    //                   return (
    //                     <div key={key} className={`w-full h-12 border grid grid-cols-3 mb-3 rounded-xl shadow-xl text-xl overflow-hidden relative ${savedTheme ? 'bg-neutral-950 shadow-neutral-950 text-white border-neutral-800' : 'shadow-gray-100 border-sky-600'}`}>
    //                       <div className='p-2 w-24 text-center bg-sky-600 text-white rounded-md rounded-tr-none rounded-br-none text-2xl -translate-y-0.5'>{key + 1}</div>
    //                       <div className='p-2 text-start -ml-10 min-w-96'>{value.name}</div>
    //                       <div className='absolute top-0 right-0 p-2 pt-2 ml-14 w-24 text-center bg-sky-200 text-black rounded-md text-2xl  rounded-tl-none rounded-bl-none' style={{transform: 'translateY(-1px) translateX(8px)'}}>{value.score}</div>
    //                     </div>
    //                   )
    //                 }
    //               }
    //             })
    //           }
    //         </div>


    //         <div className={`ml-4 border p-4 rounded-2xl ${savedTheme ? 'bg-black border-neutral-800' : 'bg-white'}`}>
    //           <div className='w-full grid grid-cols-3 -mt-2 mr-2 mb-2 font-semibold'>
    //             <div className='p-1 text-start max-w-20 ml-5'>Rank</div>
    //             <div className='p-1 text-start min-w-72 -ml-8'>Name</div>
    //             <div className='p-1 text-end mr-5'>Score</div>
    //           </div>
    //           {
    //             allEmployees.map((value, key) => {
    //               if(allEmployees[2].score > 0){
    //                 if(key > 7 && key < 12){
    //                   return (
    //                     <div key={key} className={`w-full h-12 border grid grid-cols-3 mb-3 rounded-xl shadow-xl text-xl overflow-hidden relative ${savedTheme ? 'bg-neutral-950 shadow-neutral-950 text-white border-neutral-800' : 'shadow-gray-100 border-sky-600'}`}>
    //                       <div className='p-2 pt-2 w-24 text-center bg-sky-600 text-white rounded-md text-2xl rounded-tr-none rounded-br-none -translate-y-0.5'>{key + 1}</div>
    //                       <div className='p-2 text-start -ml-10 min-w-72'>{value.name}</div>
    //                       <div className='p-2 absolute right-0 pt-2 ml-14 w-24 text-center bg-sky-200 text-black rounded-md text-2xl rounded-tl-none rounded-bl-none' style={{transform: 'translateY(-1px) translateX(8px)'}}>{value.score}</div>
    //                     </div>
    //                   )
    //                 }
    //               }

    //               else{
    //                 if(key > 4 && key < 10){
    //                   return (
    //                     <div key={key} className={`w-full h-12 border grid grid-cols-3 mb-3 rounded-xl shadow-xl text-xl overflow-hidden relative ${savedTheme ? 'bg-neutral-950 shadow-neutral-950 text-white border-neutral-800' : 'shadow-gray-100 border-sky-600'}`}>
    //                       <div className='p-2 pt-2 w-24 text-center bg-sky-600 text-white rounded-md text-2xl rounded-tr-none rounded-br-none -translate-y-0.5'>{key + 1}</div>
    //                       <div className='p-2 text-start -ml-10 min-w-72'>{value.name}</div>
    //                       <div className='p-2 absolute right-0 pt-2 ml-14 w-24 text-center bg-sky-200 text-black rounded-md text-2xl rounded-tl-none rounded-bl-none' style={{transform: 'translateY(-1px) translateX(8px)'}}>{value.score}</div>
    //                     </div>
    //                   )
    //                 }
    //               }
    //             })
    //           }
    //         </div>
    //       </div>
    //     </div>
    //   )
    // }


    if (props.theme == "purple") {
        return (
            <div className={`w-4/5 m-auto rounded-2xl p-8 pt-2 pb-2 text-xl -mt-6 bg-black bg-opacity-10`}>
                <div className={`mr-4 rounded-2xl ${savedTheme ? 'bg-black border-neutral-800' : 'bg-transparent'}`}>

                    <div className={`w-full grid grid-cols-3 -mt-2 mr-2 mb-2 relative bg-indigo-600 bg-opacity-50 text-white  `}>
                        <div className='p-1 text-start max-w-20 ml-5'>Position</div>
                        <div className='p-1 text-start min-w-72 -ml-36 -translate-x-2'>Name</div>
                        <div className='p-1 text-end mr-5 absolute right-96 ml-14'>Score</div>
                    </div>
                    {
                        allEmployees.map((value, key) => {
                            if (key < 3) {
                                return (
                                    <div key={key} className={`w-full items-center h-14 border grid grid-cols-3 mb-3 rounded-xl shadow-xl text-xl overflow-hidden relative ${savedTheme ? 'bg-neutral-950 shadow-neutral-950 text-white border-neutral-800' :
                                        key === 0 ? "bg-gradient-to-r from-[#EED176] via-[#746438] to-[#746438] border-[#EED176]" : key === 1 ? "bg-gradient-to-r from-[#E4E4E4] via-[#777777] to-[#777777] border-[#E4E4E4]" : "bg-gradient-to-r via-[#915C42] from-[#DD9165] to-[#915C42] border-[#DD9165]"
                                        }`}>

                                        <div className='p-2 pt-2 w-24 text-center text-white rounded-md text-2xl rounded-tr-none rounded-br-none -translate-y-0.5'>
                                            {key === 0 ? <img src={Medal_1} width='50px' alt="Gold Medal" /> : key === 1 ? <img src={Medal_2} width='60px' alt="Silver Medal" /> : <img src={Medal_3} width='60px' alt="Bronze Medal" />}
                                        </div>

                                        <div className='p-2 text-start -ml-40 min-w-72 text-4xl font-semibold -mt-2'>{value.name}</div>
                                        {/* Render stars based on value.score */}
                                        <div className='p-2 w-96 absolute right-32 pt-2 ml-14 justify-start rounded-md flex gap-2' style={{ transform: 'translateY(-1px) translateX(8px)' }}>
                                            {[...Array(Math.max(value.score, 0))].map((_, i) => (
                                                <img key={i}
                                                    src={key === 0 ? Rank_1_Star : key === 1 ? Rank_2_Star : Rank_3_Star}
                                                    alt={`Star ${i + 1}`} width="60px" />
                                            ))}
                                        </div>
                                    </div>
                                );


                            }
                            else if (key < 7) {
                                return (
                                    <div key={key} className={`w-full text-white items-center min-h-14 grid grid-cols-3 mb-3 rounded-xl shadow-xl text-xl overflow-hidden relative ${savedTheme ? 'bg-neutral-950 shadow-neutral-950 text-white border-neutral-800' :
                                        key % 2 === 0 ? "bg-indigo-700 bg-opacity-10" : ""
                                        }`}>
                                        <div className='p-2 pt-2 w-24 text-center rounded-md text-3xl rounded-tr-none rounded-br-none -translate-y-0.5 -translate-x-3'>
                                            {key + 1}
                                        </div>
                                        <div className='p-2 text-start -ml-40 min-w-72 text-3xl'>{value.name}</div>

                                        {/* Render stars based on value.score or display "0" if score is 0 */}
                                        <div className='p-2 flex w-96 items-center gap-2 absolute right-32 pt-2 ml-14 text-center rounded-md' style={{ transform: 'translateY(-1px) translateX(8px)' }}>
                                            {value.score > 0 ? (
                                                [...Array(value.score)].map((_, i) => (
                                                    <img key={i} src={Rank_alt_Star} alt={`Star ${i + 1}`} />
                                                ))
                                            ) : (
                                                <span></span> // Display "0" if the score is 0
                                            )}
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }


                    <div className='text-white w-full'>
                        <div className="my-10 text-4xl flex items-center justify-end">
                            <span className='text-xl fixed bottom-4 left-4'>Powered By <span className='text-2xl'>MAHAKAYA TECH</span></span>
                            <span>Total: {fastTotal}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    if (props.theme == "blue") {
        return (
            <div className={`w-4/5 m-auto rounded-2xl p-8 pt-2 pb-2 text-xl -mt-6 bg-black bg-opacity-10`}>
                <div className={`mr-4 rounded-2xl ${savedTheme ? 'bg-black border-neutral-800' : 'bg-transparent'}`}>

                    <div className={`w-full grid grid-cols-3 -mt-2 mr-2 mb-2 relative bg-indigo-600 bg-opacity-50 text-white`}>
                        <div className='p-1 text-start max-w-20 ml-5'>Position</div>
                        <div className='p-1 text-start min-w-72 -ml-36 -translate-x-2'>Name</div>
                        <div className='p-1 text-end mr-5 absolute right-96 ml-14'>Score</div>
                    </div>
                    {
                        allEmployees.map((value, key) => {
                            if (key < 3) {
                                return (
                                    <div key={key} className={`w-full items-center h-14 border grid grid-cols-3 mb-3 rounded-xl shadow-xl text-xl overflow-hidden relative ${savedTheme ? 'bg-neutral-950 shadow-neutral-950 text-white border-neutral-800' :
                                        key === 0 ? "bg-gradient-to-r from-[#EED176] via-[#746438] to-[#746438] border-[#EED176]" : key === 1 ? "bg-gradient-to-r from-[#E4E4E4] via-[#777777] to-[#777777] border-[#E4E4E4]" : "bg-gradient-to-r via-[#915C42] from-[#DD9165] to-[#915C42] border-[#DD9165]"
                                        }`}>

                                        <div className='p-2 pt-2 w-24 text-center text-white rounded-md text-2xl rounded-tr-none rounded-br-none -translate-y-0.5'>
                                            {key === 0 ? <img src={Medal_1} width='60px' alt="Gold Medal" /> : key === 1 ? <img src={Medal_2} width='60px' alt="Silver Medal" /> : <img src={Medal_3} width='60px' alt="Bronze Medal" />}
                                        </div>

                                        <div className='p-2 text-start -ml-40 min-w-72 text-[2rem] font-semibold -mt-2'>{value.name}</div>
                                        {/* Render stars based on value.score */}
                                        <div className='p-2 w-96 absolute right-32 pt-2 ml-14 justify-start rounded-md flex gap-2' style={{ transform: 'translateY(-1px) translateX(8px)' }}>
                                            {[...Array(Math.max(value.score, 0))].map((_, i) => (
                                                <img key={i}
                                                    src={key === 0 ? Rank_1_Star : key === 1 ? Rank_2_Star : Rank_3_Star}
                                                    alt={`Star ${i + 1}`} width="60px" />
                                            ))}
                                        </div>
                                    </div>
                                );


                            }
                            else if (key < 7) {
                                return (
                                    <div key={key} className={`w-full text-white items-center min-h-10 grid grid-cols-3 mb-3 rounded-xl shadow-xl text-xl overflow-hidden relative ${savedTheme ? 'bg-neutral-950 shadow-neutral-950 text-white border-neutral-800' :
                                        key % 2 === 0 ? "bg-indigo-700 bg-opacity-10" : ""
                                        }`}>
                                        <div className='p-2 pt-2 w-24 text-center rounded-md text-3xl rounded-tr-none rounded-br-none -translate-y-0.5 -translate-x-3'>
                                            {key + 1}
                                        </div>
                                        <div className='p-2 text-start -ml-40 min-w-72 text-3xl'>{value.name}</div>

                                        {/* Render stars based on value.score or display "0" if score is 0 */}
                                        <div className='p-2 flex w-96 items-center gap-2 absolute right-32 pt-2 ml-14 text-center rounded-md' style={{ transform: 'translateY(-1px) translateX(8px)' }}>
                                            {value.score > 0 ? (
                                                [...Array(value.score)].map((_, i) => (
                                                    <img key={i} src={Rank_alt_Star} alt={`Star ${i + 1}`} />
                                                ))
                                            ) : (
                                                <span></span> // Display "0" if the score is 0
                                            )}
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }

                    <div className='text-white'>
                        <div className="my-10 text-4xl flex items-center justify-end">
                            {/* <span className='text-xl'>Powered By <span className='text-2xl'>MAHAKAYA TECH</span></span> */}
                            <span>Total: {spartaTotal}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (props.theme == "sky-blue") {
        return (
            <div className={`w-4/5 m-auto rounded-2xl p-8 pt-2 pb-2 text-xl -mt-6 bg-black bg-opacity-10`}>
                <div className={`mr-4 rounded-2xl ${savedTheme ? 'bg-black border-neutral-800' : 'bg-transparent'}`}>

                    <div className={`w-full grid grid-cols-3 -mt-2 mr-2 mb-2 relative bg-indigo-600 bg-opacity-50 text-white  `}>
                        <div className='p-1 text-start max-w-20 ml-5'>Position</div>
                        <div className='p-1 text-start min-w-72 -ml-36 -translate-x-2'>Name</div>
                        <div className='p-1 text-end mr-5 absolute right-96 ml-14'>Score</div>
                    </div>
                    {
                        allEmployees.map((value, key) => {
                            if (key < 3) {
                                return (
                                    <div key={key} className={`w-full items-center h-14 border grid grid-cols-3 mb-3 rounded-xl shadow-xl text-xl overflow-hidden relative ${savedTheme ? 'bg-neutral-950 shadow-neutral-950 text-white border-neutral-800' :
                                        key === 0 ? "bg-gradient-to-r from-[#EED176] via-[#746438] to-[#746438] border-[#EED176]" : key === 1 ? "bg-gradient-to-r from-[#E4E4E4] via-[#777777] to-[#777777] border-[#E4E4E4]" : "bg-gradient-to-r via-[#915C42] from-[#DD9165] to-[#915C42] border-[#DD9165]"
                                        }`}>

                                        <div className='p-2 pt-2 w-24 text-center text-white rounded-md text-2xl rounded-tr-none rounded-br-none -translate-y-0.5'>
                                            {key === 0 ? <img src={Medal_1} width='60px' alt="Gold Medal" /> : key === 1 ? <img src={Medal_2} width='60px' alt="Silver Medal" /> : <img src={Medal_3} width='60px' alt="Bronze Medal" />}
                                        </div>

                                        <div className='p-2 text-start -ml-40 min-w-72 text-4xl font-semibold   -mt-2'>{value.name}</div>
                                        {/* Render stars based on value.score */}
                                        <div className='p-2 w-96 absolute right-32 pt-2 ml-14 justify-start rounded-md flex gap-2' style={{ transform: 'translateY(-1px) translateX(8px)' }}>
                                            {[...Array(Math.max(value.score, 0))].map((_, i) => (
                                                <img key={i}
                                                    src={key === 0 ? Rank_1_Star : key === 1 ? Rank_2_Star : Rank_3_Star}
                                                    alt={`Star ${i + 1}`} width="60px" />
                                            ))}
                                        </div>
                                    </div>
                                );


                            }
                            else if (key < 7) {
                                return (
                                    <div key={key} className={`w-full text-white items-center min-h-14 grid grid-cols-3 mb-3 rounded-xl shadow-xl text-xl overflow-hidden relative ${savedTheme ? 'bg-neutral-950 shadow-neutral-950 text-white border-neutral-800' :
                                        key % 2 === 0 ? "bg-indigo-700 bg-opacity-10" : ""
                                        }`}>
                                        <div className='p-2 pt-2 w-24 text-center rounded-md text-3xl rounded-tr-none rounded-br-none -translate-y-0.5 -translate-x-3'>
                                            {key + 1}
                                        </div>
                                        <div className='p-2 text-start -ml-40 min-w-72 text-3xl'>{value.name}</div>

                                        {/* Render stars based on value.score or display "0" if score is 0 */}
                                        <div className='p-2 flex w-96 items-center gap-2 absolute right-32 pt-2 ml-14 text-center rounded-md' style={{ transform: 'translateY(-1px) translateX(8px)' }}>
                                            {value.score > 0 ? (
                                                [...Array(value.score)].map((_, i) => (
                                                    <img key={i} src={Rank_alt_Star} alt={`Star ${i + 1}`} />
                                                ))
                                            ) : (
                                                <span></span> // Display "0" if the score is 0
                                            )}
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }

                    <div className='text-white'>
                        <div className="my-10 text-4xl flex items-center justify-end">
                            {/* <span className='text-xl'>Powered By <span className='text-2xl'>MAHAKAYA TECH</span></span> */}
                            <span>Total: {veeTotal}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProcessScores