import React, { useEffect, useState } from 'react'
import Navigation from '../Components/Navigation'
import Ranks from '../Components/Ranks'
import ProcessScores from '../Components/ProcessScores'
import sparta_logo from '../Assets/Logos/sparta.svg'
import sparta_logo_lite from '../Assets/Logos/sparta-lite.svg'
import axios from 'axios'

function Sparta(props) {
    const process = "Sparta Telecom"
    // const allEmployees = localStorage.getItem('sparta_employees') ? JSON.parse(localStorage.getItem('sparta_employees')) : []
    const [allEmployees, setAllEmployees] = useState([]);
    //useEffect(() => {
    //    axios.get("https://scorboardapi.fastranking.tech/sparta").then((response) => {
    //        setAllEmployees(response.data.data);
    //    }).catch((err) => console.log(err))
    //}, [])
    useEffect(() => {
      // Function to fetch the data
      const fetchData = () => {
        axios
          .get("https://scorboardapi.fastranking.tech/sparta")
          .then((response) => {
            setAllEmployees(response.data.data);
          })
          .catch((err) => console.log(err));
      };

        // Call fetchData immediately to avoid initial delay
        fetchData();

        // Set interval to call fetchData every 2 seconds
        const intervalId = setInterval(fetchData, 2000);

        // Cleanup function to clear the interval when the component unmounts
        return () => clearInterval(intervalId);
      }, []); // Empty dependency array ensures it runs only once after mount

    if (allEmployees.length > 2) {
        return (
            <div className='w-full'>
                <Navigation
                    logo={sparta_logo}
                    logoLite={sparta_logo_lite}
                    width={'200px'}
                    theme={props.theme}
                />
                {/* <Ranks
                    scores={[
                        {
                            rank: 1,
                            name: allEmployees[0].name ? allEmployees[0].name : "---",
                            sales_process: process,
                            sales_score: allEmployees[0].score ? allEmployees[0].score : 0,
                        },
                        {
                            rank: 2,
                            name: allEmployees[1].name ? allEmployees[1].name : "---",
                            sales_process: process,
                            sales_score: allEmployees[1].score ? allEmployees[1].score : 0,
                        },
                        {
                            rank: 3,
                            name: allEmployees[2].name ? allEmployees[2].name : "---",
                            sales_process: process,
                            sales_score: allEmployees[2].score ? allEmployees[2].score : 0,
                        }
                    ]}
                    theme={props.theme}
                /> */}
                <div className='mt-14'>
                    <ProcessScores records={allEmployees} process={process} theme={props.theme} />
                </div>
            </div>
        )
    }

    else {
        return (
            <div className='w-full'>
                <Navigation
                    logo={sparta_logo}
                    logoLite={sparta_logo_lite}
                    width={'200px'}
                    theme={props.theme}
                />

                <div className='text-center py-40'>
                    {allEmployees.length} Records Found! for Sparta Telecom
                </div>
            </div>
        )
    }
}

export default Sparta