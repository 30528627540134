import React, { useEffect, useState } from 'react'
import Navigation from '../Components/Navigation'
import Ranks from '../Components/Ranks'
import ProcessScores from '../Components/ProcessScores'
import fastranking_logo from '../Assets/Logos/Fast Ranking logo Color.svg'
import fastranking_logo_lite from '../Assets/Logos/Fast Ranking logo White.svg'
import axios from 'axios'

function Screen_1(props) {
    // const allEmployees = localStorage.getItem('fastranking_employees') ? JSON.parse(localStorage.getItem('fastranking_employees')) : []
    return (
        <div className='w-full'>
            <Navigation
                logo={fastranking_logo}
                logoLite={fastranking_logo_lite}
                width={'200px'}
                theme={props.theme}
            />


            <div className='min-w-full h-full mt-72 flex items-center justify-center text-7xl font-semibold text-white'>
                TODAY'S  <span className='text-purple-600 inline-block mx-10'>PERFORMANCE</span>  UPDATE
            </div>
        </div>
    )
}

export default Screen_1