import React, { useEffect, useState } from 'react'
import Navigation from '../Components/Navigation'
import Ranks from '../Components/Ranks'
import ProcessScores from '../Components/ProcessScores'
import fastranking_logo from '../Assets/Logos/Fast Ranking logo Color.svg'
import fastranking_logo_lite from '../Assets/Logos/Fast Ranking logo White.svg'
import axios from 'axios'

function FastRanking(props) {
    const process = "Fast Ranking"
    const [allEmployees, setAllEmployees] = useState([]);
    //useEffect(() => {
    //    axios.get("https://scorboardapi.fastranking.tech/fastranking").then((response) => {
    //        setAllEmployees(response.data.data);
    //    }).catch((err) => console.log(err))
    //}, [])
    useEffect(() => {
    // Function to fetch the data
    const fetchData = () => {
      axios
        .get("https://scorboardapi.fastranking.tech/fastranking")
        .then((response) => {
          setAllEmployees(response.data.data);
        })
        .catch((err) => console.log(err));
    };

      // Call fetchData immediately to avoid initial delay
      fetchData();

      // Set interval to call fetchData every 2 seconds
      const intervalId = setInterval(fetchData, 2000);

      // Cleanup function to clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }, []); // Empty dependency array ensures it runs only once after mount

  if(allEmployees.length > 2){
    return (
        <div className='w-full'>
            <Navigation 
                logo={fastranking_logo}
                logoLite={fastranking_logo_lite}
                width={'200px'}
                theme={props.theme}
            />
            {/* <Ranks
                theme={props.theme}
                scores={[
                    {
                        rank: 1,
                        name: allEmployees[0].name ? allEmployees[0].name : "---",
                        sales_process: process,
                        sales_score: allEmployees[0].score ? allEmployees[0].score : 0,
                    },
                    {
                        rank: 2,
                        name: allEmployees[1].name ? allEmployees[1].name : "---",
                        sales_process: process,
                        sales_score: allEmployees[1].score ? allEmployees[1].score : 0,
                    },
                    {
                        rank: 3,
                        name: allEmployees[2].name ? allEmployees[2].name : "---",
                        sales_process: process,
                        sales_score: allEmployees[2].score ? allEmployees[2].score : 0,
                    }
                ]}
                renderer={'fastranking'}
            /> */}
            
            <div className='mt-14'>
                <ProcessScores theme={props.theme} records={allEmployees} process={process} />
            </div>
        </div>
      )
  }

  else{
    return(
        <div className='w-full'>
            <Navigation 
                logo={fastranking_logo}
                logoLite={fastranking_logo_lite}
                width={'200px'}
                theme={props.theme}
            />

            <div className='text-center py-40'>
                {allEmployees.length} Records Found! for FastRanking
            </div>
        </div>
    )
  }
}

export default FastRanking