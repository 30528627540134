import React, { useEffect, useState } from 'react'
import Sparta from './Sparta'
import FastRanking from './FastRanking'
import Vee from './Vee'
import Screen_1 from './Screen_1'

function AllProcesses() {
    const [slide, setSlide] = useState('0');
    useEffect(() => {
        setTimeout(() => {
            setSlide(Number(slide+100));
            if(slide > 200){
                setSlide('0')
            }
        }, slide > 0 ? 13000 : 4000)
    }) 

    // const [allVeeRepair_employees, setAllVeeRepair_employees] = useState([])
    // const [veeScore, setVeeScore] = useState();
    // useEffect(() => {
    //     // Function to fetch the data
    //     const fetchData = () => {
    //         axios
    //             .get("https://scorboardapi.fastranking.tech/v_repair")
    //             .then((response) => {
    //                 setAllVeeRepair_employees(response.data.data);
    //             })
    //             .catch((err) => console.log(err));
    //     };

    //     // Call fetchData immediately to avoid initial delay
    //     fetchData();

    //     // Set interval to call fetchData every 2 seconds
    //     const intervalId = setInterval(fetchData, 2000);

    //     // Cleanup function to clear the interval when the component unmounts
    //     return () => clearInterval(intervalId);
    // }, []);
    // allVeeRepair_employees.length > 0 ? 
    // useEffect(() => {}, [])


  return (
    <div className='h-full w-full overflow-hidden'>
        <div className='w-full h-full flex transition-md' style={{marginLeft: `-${slide}%`}}>
            <div className='min-w-full'><Screen_1 theme="purple" /></div>
            <div className='min-w-full'><FastRanking theme="purple" /></div>
            <div className='min-w-full'><Sparta theme="blue" /></div>
            <div className='min-w-full'><Vee theme="sky-blue" /></div>
        </div>
    </div>
  )
}

export default AllProcesses