import React from 'react'
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom'
import background from "../../src/Assets/background.png"

function Layout() {
    const savedTheme = useSelector((state) => state.darkTheme);
    setTimeout(() => {
        //window.location.reload();
    }, 39000)
    return (
        <div className={`min-h-screen overflow-hidden ${savedTheme ? 'bg-black text-white' : 'bg-transparent'}`} style={{backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: "150%", backgroundPosition: "right",}}><Outlet /></div>
    )
}

export default Layout